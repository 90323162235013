<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="pic" label="缩略图" show-overflow-tooltip>
        <template #default="s">
          <img :src="s.row.pic | tomedia" style="height: 3rem;width: 3rem;border: .5rem" alt="">
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题" show-overflow-tooltip></el-table-column>
      <el-table-column prop="cate_title" label="分类" show-overflow-tooltip></el-table-column>
      <el-table-column prop="teacher_title" label="讲师" show-overflow-tooltip></el-table-column>
      <el-table-column prop="fee" label="金额" show-overflow-tooltip></el-table-column>
      <el-table-column prop="free_level" label="免费级别">
        <template #default="s">
          <div v-if="s.row.free_level">
            <el-tag size="mini" v-for="(item,key) in s.row.free_level.split(',')" :key="key">{{ userLevel2Tag(item) }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="award_fee" label="讲师佣金" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="360">
        <template #default="s">
          <el-button @click="$router.push({
          name:'shopPluginKnowledgeCourseApply',
          query:{
            id:s.row.id
          }
          })">报名列表</el-button>
          <el-button @click="$router.push({
          name:'shopPluginKnowledgeCourseColumn',
          query:{
            id:s.row.id
          }
          })">章节管理</el-button>
          <el-button @click="$router.push({
          name:'shopPluginKnowledgeCourseEdit',
          query:{
            id:s.row.id
          }
          })" type="primary">编辑</el-button>
          <el-button @click="remove(s.row)" type="danger">删除</el-button>
        </template>
        <template #header>
          <el-button @click="$router.push({
          name:'shopPluginKnowledgeCourseEdit',
          })">添加课程</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>
import {levelOption} from "@/common/mod/user";
export default {
  name: "course",
  data(){
    return{
      list:[],
      page:1,
      total:0,
    }
  },
  mounted() {
    this.load();
  },
  computed:{
    scope_option(){
      return levelOption
    }
  },
  methods:{
    userLevel2Tag(level){
      let tagObj = this.scope_option.filter(item => item.level === parseInt(level));
      if (tagObj.length === 0) {
        return "异常"
      }
      return (tagObj.pop()).title
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$u.api.shop.plugin.knowledge.course({
        page:this.page
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    remove({id}){
      this.$u.api.shop.plugin.knowledge.courseDel({id}).then(()=>{
        this.$message.success("操作成功")
        this.load();
      })
    },
  },
}
</script>

<style scoped>

</style>