var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticStyle:{"min-height":"45rem"}},[_c('el-table',{attrs:{"data":_vm.list},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('el-pagination',{staticStyle:{"padding":".5rem"},attrs:{"background":"","layout":"total,prev, pager,next","total":_vm.total,"page-size":15,"current-page":_vm.page},on:{"current-change":_vm.pageChange}})]},proxy:true}])},[_c('el-table-column',{attrs:{"prop":"id","label":"编号","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"pic","label":"缩略图","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_c('img',{staticStyle:{"height":"3rem","width":"3rem","border":".5rem"},attrs:{"src":_vm._f("tomedia")(s.row.pic),"alt":""}})]}}])}),_c('el-table-column',{attrs:{"prop":"title","label":"标题","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"cate_title","label":"分类","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"teacher_title","label":"讲师","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"fee","label":"金额","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"free_level","label":"免费级别"},scopedSlots:_vm._u([{key:"default",fn:function(s){return [(s.row.free_level)?_c('div',_vm._l((s.row.free_level.split(',')),function(item,key){return _c('el-tag',{key:key,attrs:{"size":"mini"}},[_vm._v(_vm._s(_vm.userLevel2Tag(item)))])}),1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"award_fee","label":"讲师佣金","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"操作","width":"360"},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_c('el-button',{on:{"click":function($event){return _vm.$router.push({
        name:'shopPluginKnowledgeCourseApply',
        query:{
          id:s.row.id
        }
        })}}},[_vm._v("报名列表")]),_c('el-button',{on:{"click":function($event){return _vm.$router.push({
        name:'shopPluginKnowledgeCourseColumn',
        query:{
          id:s.row.id
        }
        })}}},[_vm._v("章节管理")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
        name:'shopPluginKnowledgeCourseEdit',
        query:{
          id:s.row.id
        }
        })}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.remove(s.row)}}},[_vm._v("删除")])]}},{key:"header",fn:function(){return [_c('el-button',{on:{"click":function($event){return _vm.$router.push({
        name:'shopPluginKnowledgeCourseEdit',
        })}}},[_vm._v("添加课程")])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }